import React from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import BusinessLoanForm from "../../../components/body/pages/en-ng/business/components/forms/businessLoan"

const BusinessLoanFormPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/business/business-loan-application-form/"}
      title="Apply Business Loan in Nigeria | Purchase Order & Invoice Financing | Kuda Business"
      description="Apply for Kuda's business loans in Nigeria. Easy online application for purchase order and invoice financing. Boost your cash flow and grow your business."
    />

    <BusinessLoanForm />
  </Layout>
)

export default BusinessLoanFormPage
