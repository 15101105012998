import React, { useEffect, useState } from "react";
import SearchIcon from "../../../../assets/img/transfers-category.inline.svg"
import { titleCase } from "../../../../util/util";

export default function StateDropdown({
    list,
    headerTitle,
    key,
    onSelect,
    onBlur,
    type,
    user,
    stateObj,
    placeholder
}) {
    const [state, setState] = useState({
        headerTitle: "",
        initialItems: list,
        items: list,
        key: "",
        listOpen: false,
    });

    useEffect(() => {
        document.body.classList.add("modal-active");
        setTimeout(() => {
            if (state.listOpen) {
                window.addEventListener("click", close);
            } else {
                window.removeEventListener("click", close);
                document.body.classList.remove("modal-active");
            }
        }, 0);
        return () => {
            window.removeEventListener("click", close);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.listOpen]);

    function close() {
        setState((prevState) => ({
            ...prevState,
            listOpen: false,
        }));
    }

    function selectItem(name, stateKey, item) {

        setState((prevState) => ({
            ...prevState,
            headerTitle: name,
            listOpen: false,
            key: stateKey,
        }));
        onSelect(item);
    }

    function toggleList() {
        setState((prevState) => ({
            ...prevState,
            listOpen: !state.listOpen,
            items: list,
        }));
        return () => {
            document.querySelector('[type="search"]').focus();
        };
    }

    function filterList(event) {
        let items = state.initialItems;
        let newItems = items.filter((item) => {
            return item.name.toLowerCase().search(event.target.value.toLowerCase()) !== -1;
        });
        setState((prevState) => ({
            ...prevState,
            items: newItems,
        }));
    }

    useEffect(() => {
        if (type === "onboarding") {
            if (typeof user?.stateInfo?.name !== "undefined") {
                setState((prevState) => ({
                    ...prevState,
                    headerTitle: user?.stateInfo?.name,
                    listOpen: false,
                    key: user?.stateInfo?.id,
                }));
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, stateObj?.name]);

    useEffect(() => {
        setState(() => ({
            ...state,
            headerTitle: headerTitle || "",
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [headerTitle]);

    return (
        <React.Fragment>
            <div className="kuda-dropdown">
                <div
                    className="kuda-dropdown-header"
                    onClick={() => {
                        onBlur && onBlur();
                        toggleList();
                    }}
                >
                    <div
                        className="kuda-dropdown-icon"
                        dangerouslySetInnerHTML={{
                            __html: '<svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 7L13.9282 0.25H0.0717969L7 7Z" fill="black"/></svg>',
                        }}
                    ></div>
                    {state.headerTitle && <div className="kuda-dropdown-title">{state.headerTitle}</div>}
                    {!state.headerTitle && <div className="kuda-dropdown-placeholder">{placeholder || "Choose State"}</div>}
                </div>
            </div>
            {state.listOpen && (
                <React.Fragment>
                    <div className="kuda-searchbox" onClick={(e) => e.stopPropagation()}>
                        <SearchIcon />
                        <input type="search" placeholder="Search States" onChange={filterList} />
                    </div>
                    <ul
                        className="kuda-dropdown-list beneficiary-dropdown-list"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {state.items.map((item) => (
                            <li
                                className="kuda-dropdown-item text-semi-bold"
                                key={item.id}
                                onClick={() => selectItem(item.name, item.id, item)}
                            >
                                {titleCase(item.name)}

                            </li>
                        ))}
                        {state.items.length === 0 ? (
                            <li className="no-result">No results found</li>
                        ) : null}
                    </ul>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
